import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src549668550/src/mad-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Button, IconUser, Space, Input } from '@madup-inc/mad-design';
import { useState } from 'react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "input"
    }}>{`Input`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Input} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'() => {\n  const [value, setValue] = React.useState()\n  return (\n    <Input\n      placeholder=\"내용을 입력해 주세요.\"\n      value={value}\n      onChange={e => setValue(e.target.value)}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      IconUser,
      Space,
      Input,
      useState,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = React.useState();
        return <Input placeholder='내용을 입력해 주세요.' value={value} onChange={e => setValue(e.target.value)} mdxType="Input" />;
      }}
    </Playground>
    <h2 {...{
      "id": "different-sizes"
    }}>{`Different sizes`}</h2>
    <Playground __position={2} __code={'<Space>\n  <Input size=\"small\" />\n  <Input size=\"middle\" />\n  <Input size=\"large\" />\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      IconUser,
      Space,
      Input,
      useState,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Space mdxType="Space">
        <Input size='small' mdxType="Input" />
        <Input size='middle' mdxType="Input" />
        <Input size='large' mdxType="Input" />
    </Space>
    </Playground>
    <h2 {...{
      "id": "using-disabled"
    }}>{`Using disabled`}</h2>
    <Playground __position={3} __code={'<Input placeholder=\"입력 불가\" disabled />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      IconUser,
      Space,
      Input,
      useState,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Input placeholder='입력 불가' disabled mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "using-allowclear"
    }}>{`Using allowClear`}</h2>
    <Playground __position={4} __code={'<Input allowClear />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      IconUser,
      Space,
      Input,
      useState,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Input allowClear mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "using-onpressenter"
    }}>{`Using onPressEnter`}</h2>
    <Playground __position={5} __code={'<Input onPressEnter={e => alert(e.target.value)} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      IconUser,
      Space,
      Input,
      useState,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Input onPressEnter={e => alert(e.target.value)} mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "password-type"
    }}>{`Password type`}</h2>
    <Playground __position={6} __code={'<Input type=\"password\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      IconUser,
      Space,
      Input,
      useState,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Input type='password' mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "addonafter-addonbefore"
    }}>{`addonAfter, addonBefore`}</h2>
    <Playground __position={7} __code={'<Space direction=\"vertical\">\n  <Input addonBefore=\"https://\" addonAfter=\".com\" />\n  <Input addonAfter={<Button>추가</Button>} />\n  <Input addonAfter={<Button type=\"primary\">등록</Button>} />\n  <Input addonBefore={<IconUser />} />\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      IconUser,
      Space,
      Input,
      useState,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Space direction="vertical" mdxType="Space">
      <Input addonBefore='https://' addonAfter='.com' mdxType="Input" />
      <Input addonAfter={<Button mdxType="Button">추가</Button>} mdxType="Input" />
      <Input addonAfter={<Button type="primary" mdxType="Button">등록</Button>} mdxType="Input" />
      <Input addonBefore={<IconUser mdxType="IconUser" />} mdxType="Input" />
    </Space>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      