import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src549668550/src/mad-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Space, Button, Tag } from '@madup-inc/mad-design';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "space"
    }}>{`Space`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Space} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Space>\n  <Button>로그인</Button>\n  <Button type=\"default\">로그인</Button>\n  <Button type=\"primary\">로그인</Button>\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Space,
      Button,
      Tag,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space mdxType="Space">
    <Button mdxType="Button">로그인</Button>
    <Button type='default' mdxType="Button">로그인</Button>
    <Button type='primary' mdxType="Button">로그인</Button>
  </Space>
    </Playground>
    <h2 {...{
      "id": "vertical-direction"
    }}>{`Vertical direction`}</h2>
    <Playground __position={2} __code={'<Space direction=\"vertical\">\n  <Tag>서울</Tag>\n  <Tag>인천</Tag>\n  <Tag>부산</Tag>\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Space,
      Button,
      Tag,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space direction='vertical' mdxType="Space">
    <Tag mdxType="Tag">서울</Tag>
    <Tag mdxType="Tag">인천</Tag>
    <Tag mdxType="Tag">부산</Tag>
  </Space>
    </Playground>
    <h2 {...{
      "id": "different-sizes"
    }}>{`Different sizes`}</h2>
    <Playground __position={3} __code={'<Space direction=\"vertical\">\n  <Space size=\"small\">\n    <Tag>서울</Tag>\n    <Tag>인천</Tag>\n    <Tag>부산</Tag>\n  </Space>\n  <Space size=\"middle\">\n    <Tag>서울</Tag>\n    <Tag>인천</Tag>\n    <Tag>부산</Tag>\n  </Space>\n  <Space size=\"large\">\n    <Tag>서울</Tag>\n    <Tag>인천</Tag>\n    <Tag>부산</Tag>\n  </Space>\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Space,
      Button,
      Tag,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space direction='vertical' mdxType="Space">
    <Space size='small' mdxType="Space">
      <Tag mdxType="Tag">서울</Tag>
      <Tag mdxType="Tag">인천</Tag>
      <Tag mdxType="Tag">부산</Tag>
    </Space>
    <Space size='middle' mdxType="Space">
      <Tag mdxType="Tag">서울</Tag>
      <Tag mdxType="Tag">인천</Tag>
      <Tag mdxType="Tag">부산</Tag>
    </Space>
    <Space size='large' mdxType="Space">
      <Tag mdxType="Tag">서울</Tag>
      <Tag mdxType="Tag">인천</Tag>
      <Tag mdxType="Tag">부산</Tag>
    </Space>
  </Space>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      