import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src549668550/src/mad-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Checkbox, CheckboxGroup } from '@madup-inc/mad-design';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "checkbox"
    }}>{`Checkbox`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Checkbox} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<p>Label</p>\n<ul>\n  <li>\n    <Checkbox />\n  </li>\n  <li>\n    <Checkbox>Label</Checkbox>\n  </li>\n</ul>\n<p>Checked</p>\n<Checkbox checked={false} onChange={() => null}>\n  False\n</Checkbox>\n<Checkbox checked onChange={() => null}>\n  True\n</Checkbox>\n<p>Default Checked</p>\n<Checkbox defaultChecked>True</Checkbox>\n<p>Disabled</p>\n<Checkbox disabled>Disabled only</Checkbox>\n<Checkbox checked disabled>\n  Disabled with checked\n</Checkbox>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Checkbox,
      CheckboxGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <p>Label</p>
  <ul>
    <li>
      <Checkbox mdxType="Checkbox" />
    </li>
    <li>
      <Checkbox mdxType="Checkbox">Label</Checkbox>
    </li>
  </ul>
  <p>Checked</p>
  <Checkbox checked={false} onChange={() => null} mdxType="Checkbox">False</Checkbox>
  <Checkbox checked onChange={() => null} mdxType="Checkbox">True</Checkbox>
  <p>Default Checked</p>
  <Checkbox defaultChecked mdxType="Checkbox">True</Checkbox>
  <p>Disabled</p>
  <Checkbox disabled mdxType="Checkbox">Disabled only</Checkbox>
  <Checkbox checked disabled mdxType="Checkbox">Disabled with checked</Checkbox>
    </Playground>
    <h2 {...{
      "id": "different-colors"
    }}>{`Different colors`}</h2>
    <Playground __position={2} __code={'<Checkbox defaultChecked>Default</Checkbox>\n<Checkbox defaultChecked color=\"primary\">\n  Primary\n</Checkbox>\n<Checkbox defaultChecked color=\"green\">\n  Green\n</Checkbox>\n<Checkbox defaultChecked color=\"gray\">\n  Gray\n</Checkbox>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Checkbox,
      CheckboxGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Checkbox defaultChecked mdxType="Checkbox">Default</Checkbox>
  <Checkbox defaultChecked color='primary' mdxType="Checkbox">Primary</Checkbox>
  <Checkbox defaultChecked color='green' mdxType="Checkbox">Green</Checkbox>
  <Checkbox defaultChecked color='gray' mdxType="Checkbox">Gray</Checkbox>
    </Playground>
    <h2 {...{
      "id": "different-icons"
    }}>{`Different icons`}</h2>
    <Playground __position={3} __code={'<p>String Icon</p>\n<ul>\n  <li>\n    <Checkbox icon=\"✓\" defaultChecked>\n      Check1\n    </Checkbox>\n    <Checkbox icon=\"✔\" defaultChecked>\n      Check2\n    </Checkbox>\n    <Checkbox icon=\"✕\" defaultChecked>\n      icon x\n    </Checkbox>\n    <Checkbox icon=\"#\" defaultChecked>\n      icon #\n    </Checkbox>\n  </li>\n</ul>\n<p>Element Icon</p>\n<ul>\n  <li>\n    <Checkbox icon={<strong>굳</strong>} defaultChecked>\n      element1\n    </Checkbox>\n    <Checkbox icon={<div>Y</div>} defaultChecked>\n      element2\n    </Checkbox>\n    <Checkbox icon={<div>N</div>} defaultChecked>\n      element3\n    </Checkbox>\n  </li>\n</ul>\n<p>Icon size</p>\n<ul>\n  <li>\n    <Checkbox icon=\"1\" iconSize=\"sm\" defaultChecked>\n      Small\n    </Checkbox>\n    <Checkbox icon=\"2\" iconSize=\"md\" defaultChecked>\n      Medium\n    </Checkbox>\n    <Checkbox icon=\"3\" iconSize=\"lg\" defaultChecked>\n      Large\n    </Checkbox>\n  </li>\n</ul>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Checkbox,
      CheckboxGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <p>String Icon</p>
  <ul>
    <li>
      <Checkbox icon='✓' defaultChecked mdxType="Checkbox">Check1</Checkbox>
      <Checkbox icon='✔' defaultChecked mdxType="Checkbox">Check2</Checkbox>
      <Checkbox icon='✕' defaultChecked mdxType="Checkbox">icon x</Checkbox>
      <Checkbox icon='#' defaultChecked mdxType="Checkbox">icon #</Checkbox>
    </li>
  </ul>
  <p>Element Icon</p>
  <ul>
    <li>
      <Checkbox icon={<strong>굳</strong>} defaultChecked mdxType="Checkbox">element1</Checkbox>
      <Checkbox icon={<div>Y</div>} defaultChecked mdxType="Checkbox">element2</Checkbox>
      <Checkbox icon={<div>N</div>} defaultChecked mdxType="Checkbox">element3</Checkbox>
    </li>
  </ul>
  <p>Icon size</p>
  <ul>
    <li>
      <Checkbox icon='1' iconSize='sm' defaultChecked mdxType="Checkbox">Small</Checkbox>
      <Checkbox icon='2' iconSize='md' defaultChecked mdxType="Checkbox">Medium</Checkbox>
      <Checkbox icon='3' iconSize='lg' defaultChecked mdxType="Checkbox">Large</Checkbox>
    </li>
  </ul>
    </Playground>
    <h2 {...{
      "id": "different-shape"
    }}>{`Different shape`}</h2>
    <Playground __position={4} __code={'<Checkbox icon=\"1\" color=\"primary\" defaultChecked>\n  Default\n</Checkbox>\n<Checkbox icon=\"2\" shape=\"rounded\" color=\"primary\" defaultChecked>\n  Rounded\n</Checkbox>\n<Checkbox icon=\"3\" shape=\"circle\" color=\"primary\" defaultChecked>\n  Circle\n</Checkbox>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Checkbox,
      CheckboxGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Checkbox icon='1' color='primary' defaultChecked mdxType="Checkbox">Default</Checkbox>
    <Checkbox icon='2' shape='rounded' color='primary' defaultChecked mdxType="Checkbox">Rounded</Checkbox>
    <Checkbox icon='3' shape='circle' color='primary' defaultChecked mdxType="Checkbox">Circle</Checkbox>
    </Playground>
    <h2 {...{
      "id": "different-fill-mode"
    }}>{`Different fill mode`}</h2>
    <Playground __position={5} __code={'<Checkbox icon=\"#\" color=\"primary\" defaultChecked>\n  Default\n</Checkbox>\n<Checkbox icon=\"1\" fillMode=\"less\" color=\"primary\" defaultChecked>\n  Less\n</Checkbox>\n<Checkbox icon=\"2\" fillMode=\"few\" color=\"primary\" defaultChecked>\n  Few\n</Checkbox>\n<Checkbox icon=\"3\" fillMode=\"full\" color=\"primary\" defaultChecked>\n  Full\n</Checkbox>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Checkbox,
      CheckboxGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Checkbox icon='#' color='primary' defaultChecked mdxType="Checkbox">Default</Checkbox>
    <Checkbox icon='1' fillMode='less' color='primary' defaultChecked mdxType="Checkbox">Less</Checkbox>
    <Checkbox icon='2' fillMode='few' color='primary' defaultChecked mdxType="Checkbox">Few</Checkbox>
    <Checkbox icon='3' fillMode='full' color='primary' defaultChecked mdxType="Checkbox">Full</Checkbox>
    </Playground>
    <h2 {...{
      "id": "with-onchange"
    }}>{`With onChange`}</h2>
    <Playground __position={6} __code={'<Checkbox icon=\"#\" onChange={e => alert(e.target.checked)}>\n  Default\n</Checkbox>\n<Checkbox icon=\"#\" value=\"example\" onChange={e => alert(e.target.value)}>\n  Default\n</Checkbox>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Checkbox,
      CheckboxGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Checkbox icon='#' onChange={e => alert(e.target.checked)} mdxType="Checkbox">Default</Checkbox>
    <Checkbox icon='#' value="example" onChange={e => alert(e.target.value)} mdxType="Checkbox">Default</Checkbox>
    </Playground>
    <h2 {...{
      "id": "checkbox-group"
    }}>{`Checkbox group`}</h2>
    <h2 {...{
      "id": "properties-1"
    }}>{`Properties`}</h2>
    <Props of={CheckboxGroup} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage-1"
    }}>{`Basic usage`}</h2>
    <Playground __position={8} __code={'<CheckboxGroup onChange={list => alert(list)}>\n  <Checkbox value=\"example1\">1</Checkbox>\n  <Checkbox value=\"example2\">2</Checkbox>\n  <Checkbox value=\"example3\">3</Checkbox>\n</CheckboxGroup>\n<hr />\n<CheckboxGroup\n  defaultValues={[\'example1\', \'example2\']}\n  onChange={list => alert(list)}\n>\n  <Checkbox value=\"example1\">1</Checkbox>\n  <Checkbox value=\"example2\">2</Checkbox>\n  <Checkbox value=\"example3\">3</Checkbox>\n</CheckboxGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Checkbox,
      CheckboxGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CheckboxGroup onChange={list => alert(list)} mdxType="CheckboxGroup">
    <Checkbox value="example1" mdxType="Checkbox">1</Checkbox>
    <Checkbox value="example2" mdxType="Checkbox">2</Checkbox>
    <Checkbox value="example3" mdxType="Checkbox">3</Checkbox>
  </CheckboxGroup>
  <hr />
  <CheckboxGroup defaultValues={['example1', 'example2']} onChange={list => alert(list)} mdxType="CheckboxGroup">
    <Checkbox value="example1" mdxType="Checkbox">1</Checkbox>
    <Checkbox value="example2" mdxType="Checkbox">2</Checkbox>
    <Checkbox value="example3" mdxType="Checkbox">3</Checkbox>
  </CheckboxGroup>
    </Playground>
    <h2 {...{
      "id": "with-options"
    }}>{`With options`}</h2>
    <Playground __position={9} __code={'<CheckboxGroup\n  defaultValues={[\'Apple\', \'Pear\']}\n  options={[\'Apple\', \'Pear\', \'Orange\']}\n  onChange={list => alert(list)}\n/>\n<CheckboxGroup\n  options={[\n    { label: \'Apple1\', value: \'Apple11\' },\n    { label: \'Pear1\', value: \'Pear11\' },\n    { label: \'Orange1\', value: \'Orange11\' },\n  ]}\n  onChange={list => alert(list)}\n/>\n<CheckboxGroup\n  defaultValues={[\'Apple22\', \'Pear22\']}\n  options={[\n    { label: \'Apple2\', value: \'Apple22\' },\n    { label: \'Pear2\', value: \'Pear22\', color: \'primary\' },\n    { label: \'Orange2\', value: \'Orange22\', disabled: true },\n    { label: \'Tomato2\', value: \'Tomato22\', shape: \'rounded\' },\n  ]}\n  onChange={list => alert(list)}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Checkbox,
      CheckboxGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CheckboxGroup defaultValues={['Apple', 'Pear']} options={['Apple', 'Pear', 'Orange']} onChange={list => alert(list)} mdxType="CheckboxGroup" />
  <CheckboxGroup options={[{
        label: 'Apple1',
        value: 'Apple11'
      }, {
        label: 'Pear1',
        value: 'Pear11'
      }, {
        label: 'Orange1',
        value: 'Orange11'
      }]} onChange={list => alert(list)} mdxType="CheckboxGroup" />
  <CheckboxGroup defaultValues={['Apple22', 'Pear22']} options={[{
        label: 'Apple2',
        value: 'Apple22'
      }, {
        label: 'Pear2',
        value: 'Pear22',
        color: "primary"
      }, {
        label: 'Orange2',
        value: 'Orange22',
        disabled: true
      }, {
        label: 'Tomato2',
        value: 'Tomato22',
        shape: 'rounded'
      }]} onChange={list => alert(list)} mdxType="CheckboxGroup" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      