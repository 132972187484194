import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src549668550/src/mad-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { ImageViewer, Button } from '@madup-inc/mad-design';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "image"
    }}>{`Image`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={ImageViewer} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Image } from './Icon'

export default {
  return <ImageViewer src='https://lever.me/statics/image/knowHow/9-1.jpg' />
}
`}</code></pre>
    <Playground __position={1} __code={'() => {\n  const [visible, setVisible] = React.useState(false)\n  return (\n    <div>\n      <Button onClick={() => setVisible(true)}>preview image</Button>\n      {visible && (\n        <ImageViewer\n          src=\"https://lever.me/statics/image/knowHow/9-1.jpg\"\n          onClose={() => setVisible(false)}\n        />\n      )}\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ImageViewer,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [visible, setVisible] = React.useState(false);
        return <div>
        <Button onClick={() => setVisible(true)} mdxType="Button">preview image</Button>
        {visible && <ImageViewer src='https://lever.me/statics/image/knowHow/9-1.jpg' onClose={() => setVisible(false)} mdxType="ImageViewer" />}
      </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      