import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src549668550/src/mad-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import '@madup-inc/mad-design/dist/index.css';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "mad-design"
    }}>{`mad-design`}</h1>
    <p>{`Awesome design system of LEVER 😀`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      